import { AppleOutlined, GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';

import { AuthServiceProvider } from '../../components/AuthProvider';
import FullscreenLoading from '../../components/FullscreenLoading';
import useAuthenticate, { AuthenticateType } from '../../hooks/authenticate';
import ModalLayout from '../../layout/ModalLayout';
import styles from './index.module.css';

const { Title, Text } = Typography;

const SignInScreen = () => {
  const [authenticate, authenticateRequest] = useAuthenticate(
    AuthenticateType.LOGIN,
  );

  if (authenticateRequest.isLoading) {
    return <FullscreenLoading message="Checking your credentials..." />;
  }

  return (
    <ModalLayout title="Login to Nala - Born in Africa, built for you">
      <div className={styles.wrapper}>
        <Title className={styles.greeting}>
          Login to
          <br />
          Nala Business
        </Title>
        <Text className={styles['signup-prompt']}>
          {"Don't have an account yet? "}
          <a href="https://app.nala.money">Download the app and sign up.</a>
        </Text>
        <div className={styles['oauth-login']}>
          <Button
            size="large"
            block
            icon={<MailOutlined />}
            loading={authenticateRequest.isAuthenticating}
            onClick={() => authenticate(AuthServiceProvider.Email)}
            id="g-email-login"
          >
            Login with email
          </Button>
          <Button
            size="large"
            block
            icon={<GoogleOutlined />}
            loading={authenticateRequest.isAuthenticating}
            onClick={() => authenticate(AuthServiceProvider.Google)}
            id="g-google-login"
          >
            Continue with Google
          </Button>
          <Button
            size="large"
            block
            icon={<AppleOutlined />}
            loading={authenticateRequest.isAuthenticating}
            onClick={() => authenticate(AuthServiceProvider.Apple)}
            id="g-apple-login"
          >
            Continue with Apple
          </Button>

          {authenticateRequest.isError && (
            <p>Unable to authenticate, please try again</p>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default SignInScreen;
